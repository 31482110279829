<template>
  <div class="campanha">
    <!-- <Simulation :landingPageOptions="landingPageOptions" /> -->
    <Icon />
    <Nav :landingPageOptions="landingPageOptions" />
    <router-view :landingPageOptions="landingPageOptions"/>
  </div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Icon from "@/components/Icon.vue";
import Simulation from "@/components/Simulation.vue";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import CampaignService from "@/scripts/services/campaign.service";

export default {
  components: {
    Nav,
    Icon,
    Simulation
  },
  created() {
    if (this.landingPageOptions.logo === null) {
      this.campaignService.getCampaignLandingPageConfigBySubdomain().then((data) => {
        this.landingPageOptions.logo = data.campaign.file
      })
    }
  },
  data() {
    return {
      campaignService: new CampaignService(),
      landingPageOptions: new CampaignLandingPageOptions()
    };
  },
};
</script>

